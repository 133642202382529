<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title class="primary">
          <span style="color: #fff" class="text-h5">Duplicar Aula</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <ValidationObserver v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(enableSubmit)">
                <v-row>
                  <v-col class="pt-4 pb-0" cols="12">
                    <e-label class="font-weight-medium">Tipo de Aula</e-label>
                    <p class="font-weight-regular">{{ aulaDuplicada.tipo_de_aula }}</p>
                  </v-col>
                  <v-col class="pt-0 pb-0" cols="12">
                    <v-dialog
                      ref="dialog"
                      v-model="modal"
                      :return-value.sync="dataAulaDuplicada"
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <e-label>Selecione uma data</e-label>
                        <ValidationProvider name="Data" rules="required" v-slot="{ errors }">
                          <v-text-field
                            :error-messages="errors"
                            v-model="data"
                            persistent-hint
                            label="Escolha uma data"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            dense
                            solo
                          ></v-text-field>
                        </ValidationProvider>
                      </template>
                      <v-date-picker
                        v-model="dataAulaDuplicada"
                        scrollable
                        @input="obterDiaDaSemana"
                        :allowed-dates="allowedDates"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="modal = false">Cancel</v-btn>
                        <v-btn text color="primary" @click="$refs.dialog.save(dataAulaDuplicada)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <v-col class="pt-0 pb-0" cols="4">
                    <e-label v-if="momentoSelecionadoDescricao.length">
                      {{ momentoSelecionadoDescricao[0] }} {{ momentoSelecionadoDescricao[1] }}
                    </e-label>
                    <e-label v-else>Nenhum Momento Adicionado</e-label>
                    <v-btn color="primary" block @click="rotinas = true">Adicionar Momento</v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <e-label class="font-weight-medium">Conteúdo</e-label>
                    <p class="font-weight-regular">{{ aulaDuplicada.conteudo }}</p>
                  </v-col>
                  <v-col cols="12">
                    <e-label class="font-weight-medium">Metodologia</e-label>
                    <p class="font-weight-regular">{{ aulaDuplicada.metodologia }}</p>
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="red darken-1"
                    style="color: #fff"
                    @click="$emit('dialogChange', false)"
                  >
                    Fechar
                  </v-btn>
                  <v-btn class="green darken-1" style="color: #fff" type="submit">Salvar</v-btn>
                </v-card-actions>
              </form>
            </ValidationObserver>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <e-modal-confirm
      :confirmDialog="confirmDialog"
      :objectModal="objectModal"
      @changeModalConfirm="enableSubmit"
      @changeActionResponse="duplicarAula"
    ></e-modal-confirm>
    <v-bottom-sheet v-model="rotinas" scrollable>
      <v-sheet class="text-center pa-md-8 mx-lg-auto" height="auto">
        <v-btn color="error" dark small @click="rotinas = !rotinas">Fechar</v-btn>
        <template v-if="aulaDuplicada.data">
          <h2 class="mt-4">
            Momentos disponíveis na {{ diaDaSemanaSelecionado }}-feira ({{ data }})
          </h2>
          <v-simple-table class="mt-4" style="border: 1px solid #e0e0e0">
            <thead style="background-color: #e0e0e0">
              <tr>
                <th class="text-left">SEG</th>
                <th class="text-left">TER</th>
                <th class="text-left">QUA</th>
                <th class="text-left">QUI</th>
                <th class="text-left">SEX</th>
              </tr>
            </thead>
            <tbody class="text-left">
              <tr>
                <td>
                  <v-treeview
                    open-all
                    item-disabled="locked"
                    :items="itensHorariosDisponiveisSeg"
                    @update:active="verificarSelecionado"
                    shaped
                    hoverable
                    activatable
                  ></v-treeview>
                </td>
                <td>
                  <v-treeview
                    open-all
                    item-disabled="locked"
                    :items="itensHorariosDisponiveisTer"
                    @update:active="verificarSelecionado"
                    shaped
                    hoverable
                    activatable
                  ></v-treeview>
                </td>
                <td>
                  <v-treeview
                    open-all
                    item-disabled="locked"
                    :items="itensHorariosDisponiveisQua"
                    @update:active="verificarSelecionado"
                    shaped
                    hoverable
                    activatable
                  ></v-treeview>
                </td>
                <td>
                  <v-treeview
                    open-all
                    item-disabled="locked"
                    :items="itensHorariosDisponiveisQui"
                    @update:active="verificarSelecionado"
                    shaped
                    hoverable
                    activatable
                  ></v-treeview>
                </td>
                <td>
                  <v-treeview
                    open-all
                    item-disabled="locked"
                    :items="itensHorariosDisponiveisSex"
                    @update:active="verificarSelecionado"
                    shaped
                    hoverable
                    activatable
                  ></v-treeview>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </template>
        <template v-else>
          <v-alert class="mt-4" type="warning">Por favor, selecione primeiro uma data!</v-alert>
        </template>
      </v-sheet>
    </v-bottom-sheet>
  </v-row>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    submittingForm: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      required: true,
    },
    gestaoDeAulas: {
      type: Object,
      required: true,
    },
    gestaoDeAula: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data: null,
      modal: false,
      aulaDuplicada: {},
      dataAulaDuplicada: null,
      confirmDialog: false,
      objectModal: {
        title: "Duplicar aula",
        message: "Tem certeza que deseja duplicar esta aula?",
      },
      momentoSelecionadoDescricao: [],
      rotinas: false,
      itensHorariosDisponiveisSeg: [],
      itensHorariosDisponiveisTer: [],
      itensHorariosDisponiveisQua: [],
      itensHorariosDisponiveisQui: [],
      itensHorariosDisponiveisSex: [],
      diaDaSemanaSelecionado: null,
    };
  },
  watch: {
    form: {
      immediate: true,
      handler(val) {
        this.aulaDuplicada = val;
      },
    },
    dataAulaDuplicada(value) {
      const date = new Date(value);
      const data_old = new Intl.DateTimeFormat("pt-BR", { timeZone: "UTC" }).format(date);
      this.data = data_old.replaceAll("/", "/");
    },
  },
  methods: {
    enableSubmit() {
      this.confirmDialog = !this.confirmDialog;
      this.aulaDuplicada.data = this.dataAulaDuplicada;
    },
    allowedDates(val) {
      const dias = JSON.parse(this.gestaoDeAula.momentos);
      const arrayPermitidos = [];
      const segunda = Array.isArray(dias.segunda) ? arrayPermitidos.push(0) : false;
      const terca = Array.isArray(dias.terca) ? arrayPermitidos.push(1) : false;
      const quarta = Array.isArray(dias.quarta) ? arrayPermitidos.push(2) : false;
      const quinta = Array.isArray(dias.quinta) ? arrayPermitidos.push(3) : false;
      const sexta = Array.isArray(dias.sexta) ? arrayPermitidos.push(4) : false;

      const array = arrayPermitidos;
      return array.includes(new Date(val).getDay());
    },
    obterDiaDaSemana() {
      const partes = this.aulaDuplicada.data.split("/");
      const dataFormatada = `${partes[2]}-${partes[1]}-${partes[0]}`;
      this.aulaDuplicada.data = dataFormatada;
      const diaDaSemana = new Date(this.dataAulaDuplicada).getDay();
      const diasDaSemana = [
        "Segunda-feira",
        "Terça-feira",
        "Quarta-feira",
        "Quinta-feira",
        "Sexta-feira",
        "Sábado",
        "Domingo",
      ];

      this.aulaDuplicada.dia_da_semana = diasDaSemana[diaDaSemana];
      if (this.dataAulaDuplicada) {
        const diaDaSemana = new Date(this.dataAulaDuplicada).getDay();
        const diasDaSemana = ["segunda", "terca", "quarta", "quinta", "sexta", "sabado", "domingo"];
        const nomeDoDiaDaSemana = diasDaSemana[diaDaSemana];
        this.diaDaSemanaSelecionado = nomeDoDiaDaSemana;
        this.loadMomentos();
      }
    },

    async loadMomentos() {
      /// inicio rotinas momentos
      this.rotina = JSON.parse(this.gestaoDeAula.momentos);

      // segunda-feira
      const arrayNovoSeg = [];
      if (Array.isArray(this.rotina.segunda)) {
        this.rotina.segunda.map((idSelecionado) => {
          return this.$constants.rotinasTurma.map((item) => {
            return item.children.map((subItem) => {
              if (subItem.id === idSelecionado) {
                arrayNovoSeg.push(subItem);
              }
            });
          });
        });

        const rotinaNovaSelecionadaSegunda = [];
        arrayNovoSeg.map((item) => {
          const search = rotinaNovaSelecionadaSegunda.find((subItem) => subItem.id === item.pai);
          if (search) {
            search.children.push(item);
          } else {
            const getName = this.$constants.rotinasTurma.filter(
              (rotina) => rotina.id === item.pai
            )[0].name;
            const itemNovo = {
              id: item.pai,
              name: getName,
              locked: this.diaDaSemanaSelecionado !== "segunda",
              children: [],
            };
            rotinaNovaSelecionadaSegunda.push(itemNovo);
            const search = rotinaNovaSelecionadaSegunda.find((subItem) => subItem.id === item.pai);
            search.children.push(item);
          }
        });

        this.itensHorariosDisponiveisSeg = rotinaNovaSelecionadaSegunda;
      }

      // terca-feira
      const arrayNovoTer = [];
      if (Array.isArray(this.rotina.terca)) {
        this.rotina.terca.map((idSelecionado) => {
          return this.$constants.rotinasTurma.map((item) => {
            return item.children.map((subItem) => {
              if (subItem.id === idSelecionado) {
                arrayNovoTer.push(subItem);
              }
            });
          });
        });

        const rotinaNovaSelecionadaTerca = [];
        arrayNovoTer.map((item) => {
          const search = rotinaNovaSelecionadaTerca.find((subItem) => subItem.id === item.pai);
          if (search) {
            search.children.push(item);
          } else {
            const getName = this.$constants.rotinasTurma.filter(
              (rotina) => rotina.id === item.pai
            )[0].name;
            const itemNovo = {
              id: item.pai,
              name: getName,
              locked: this.diaDaSemanaSelecionado !== "terca",
              children: [],
            };
            rotinaNovaSelecionadaTerca.push(itemNovo);
            const search = rotinaNovaSelecionadaTerca.find((subItem) => subItem.id === item.pai);
            search.children.push(item);
          }
        });

        this.itensHorariosDisponiveisTer = rotinaNovaSelecionadaTerca;
      }

      // quarta-feira
      const arrayNovoQua = [];

      if (Array.isArray(this.rotina.quarta)) {
        this.rotina.quarta.map((idSelecionado) => {
          return this.$constants.rotinasTurma.map((item) => {
            return item.children.map((subItem) => {
              if (subItem.id === idSelecionado) {
                arrayNovoQua.push(subItem);
              }
            });
          });
        });

        const rotinaNovaSelecionadaQuarta = [];
        arrayNovoQua.map((item) => {
          const search = rotinaNovaSelecionadaQuarta.find((subItem) => subItem.id === item.pai);
          if (search) {
            search.children.push(item);
          } else {
            const getName = this.$constants.rotinasTurma.filter(
              (rotina) => rotina.id === item.pai
            )[0].name;
            const itemNovo = {
              id: item.pai,
              name: getName,
              locked: this.diaDaSemanaSelecionado !== "quarta",
              children: [],
            };
            rotinaNovaSelecionadaQuarta.push(itemNovo);
            const search = rotinaNovaSelecionadaQuarta.find((subItem) => subItem.id === item.pai);
            search.children.push(item);
          }
        });

        this.itensHorariosDisponiveisQua = rotinaNovaSelecionadaQuarta;
      }

      // quinta-feira
      const arrayNovoQui = [];

      if (Array.isArray(this.rotina.quinta)) {
        this.rotina.quinta.map((idSelecionado) => {
          return this.$constants.rotinasTurma.map((item) => {
            return item.children.map((subItem) => {
              if (subItem.id === idSelecionado) {
                arrayNovoQui.push(subItem);
              }
            });
          });
        });

        const rotinaNovaSelecionadaQuinta = [];
        arrayNovoQui.map((item) => {
          const search = rotinaNovaSelecionadaQuinta.find((subItem) => subItem.id === item.pai);
          if (search) {
            search.children.push(item);
          } else {
            const getName = this.$constants.rotinasTurma.filter(
              (rotina) => rotina.id === item.pai
            )[0].name;
            const itemNovo = {
              id: item.pai,
              name: getName,
              locked: this.diaDaSemanaSelecionado !== "quinta",
              children: [],
            };
            rotinaNovaSelecionadaQuinta.push(itemNovo);
            const search = rotinaNovaSelecionadaQuinta.find((subItem) => subItem.id === item.pai);
            search.children.push(item);
          }
        });

        this.itensHorariosDisponiveisQui = rotinaNovaSelecionadaQuinta;
      }

      // sexta-feira
      const arrayNovoSex = [];

      if (Array.isArray(this.rotina.sexta)) {
        this.rotina.sexta.map((idSelecionado) => {
          return this.$constants.rotinasTurma.map((item) => {
            return item.children.map((subItem) => {
              if (subItem.id === idSelecionado) {
                arrayNovoSex.push(subItem);
              }
            });
          });
        });

        const rotinaNovaSelecionadaSexta = [];
        arrayNovoSex.map((item) => {
          const search = rotinaNovaSelecionadaSexta.find((subItem) => subItem.id === item.pai);
          if (search) {
            search.children.push(item);
          } else {
            const getName = this.$constants.rotinasTurma.filter(
              (rotina) => rotina.id === item.pai
            )[0].name;
            const itemNovo = {
              id: item.pai,
              name: getName,
              locked: this.diaDaSemanaSelecionado !== "sexta",
              children: [],
            };
            rotinaNovaSelecionadaSexta.push(itemNovo);
            const search = rotinaNovaSelecionadaSexta.find((subItem) => subItem.id === item.pai);
            search.children.push(item);
          }
        });

        this.itensHorariosDisponiveisSex = rotinaNovaSelecionadaSexta;
      }

      /// fim rotinas momentos
    },
    verificarSelecionado(item) {
      const momentosNaoPode = [1, 5, 9, 13, 17, 21, 25, 29, 33, 37, 41];
      if (momentosNaoPode.includes(item[0])) {
        this.$toast.danger("Selecione um turno do momento!!");
      } else {
        this.momentoSelecionado = item[0];

        this.$constants.rotinasTurma.map((item) => {
          return item.children.map((subItem) => {
            if (subItem.id === this.momentoSelecionado) {
              const pai = subItem.pai;

              const paiName = this.$constants.rotinasTurma.filter((item) => item.id === pai)[0]
                .name;

              this.momentoSelecionadoDescricao = [];
              this.momentoSelecionadoDescricao.push(paiName);
              this.momentoSelecionadoDescricao.push(subItem.name);
            }
          });
        });

        this.$toast.success("Momento Selecionado com sucesso!");
        this.rotinas = false;
      }
    },
    async duplicarAula() {
      this.$loaderService.open("Duplicando a Aula");
      try {
        this.aulaDuplicada.horario_inicial = this.horarioSelecionado
          ? this.horarioSelecionado.inicio
          : null;
        this.aulaDuplicada.horario_final = this.horarioSelecionado
          ? this.horarioSelecionado.final
          : null;
        this.aulaDuplicada.horario_id = this.horarioSelecionado ? this.horarioSelecionado.id : null;
        this.aulaDuplicada.gestao_de_aula = parseInt(this.$route.params.gestaoDeAulaId, 10);
        this.aulaDuplicada.disciplina_id = this.gestaoDeAula.disciplina_id;
        this.aulaDuplicada.turma_id = this.gestaoDeAula.turma_id;
        this.aulaDuplicada.instrutor_id = this.gestaoDeAula.instrutor_id;
        this.aulaDuplicada.momento = this.momentoSelecionado;
        await this.$services.aulasService.criar(this.aulaDuplicada);
        this.confirmDialog = !this.confirmDialog;
        this.$emit("dialogChange", false);
        this.$toast.success("Aula duplicada com sucesso!");
        // this.$router.push({ name: "aulas" });
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
  },
};
</script>
