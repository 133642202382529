import { $toast } from "vuetify-snackbar-toast";

export default function $handleError(error) {
  if (!error?.message?.includes("Unauthenticated")) {
    if (error?.message) {
      $toast.danger(error?.message);
      return;
    }

    if (error.error.message) {
      $toast.danger(error.error.message);
      return;
    }

    $toast.danger(error);
  }
}

export const handleErrorPlugin = (vue) => {
  vue.prototype.$handleError = $handleError;
};
