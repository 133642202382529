<template>
  <v-navigation-drawer
    :value="menuOpen"
    @input="(newValue) => $store.commit('MainTemplate/setMenuOpen', newValue)"
    color="grey lighten-5"
    app
  >
    <v-list-item class="px-2 py-5">
      <v-list-item-content>
        <v-list-item-title class="d-flex justify-center">
          <router-link :to="{ name: 'home' }">
            <img src="/img/logo.png" width="100" class="mb-5" />
          </router-link>
        </v-list-item-title>
        <v-list-item-subtitle class="d-flex justify-center">
          <e-ano-selector class="" />
          <!-- <e-franquia-selector class="ml-2" /> -->
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-list dense>
      <v-list-group
        v-for="item in menu.items.filter(
          (item) =>
            item.exibir && item.children.filter((child) => $can(child.permissions)).length > 0
        )"
        :key="item.title"
        v-model="item.active"
        no-action
        :append-icon="item.children && item.children.length > 0 ? undefined : ''"
        :prepend-icon="item.icon"
        @click="() => (item.to ? $router.push(item.to) : undefined)"
      >
        <template v-slot:activator>
          <!-- <v-list-item
                :to="item.to || undefined"
                link
              > -->
          <v-list-item-content>
            <v-list-item-title v-text="item.title" />
          </v-list-item-content>
          <!-- </v-list-item
              > -->
        </template>
        <v-list-item
          v-for="child in item.children"
          v-show="$can(child.permissions)"
          :key="child.title"
          :to="child.to"
          :href="child.href"
          :target="child.target"
        >
          <v-divider v-if="child.type === 'spacer'" />
          <v-list-item-content v-if="child.type !== 'spacer'">
            <v-list-item-title v-text="child.title" />
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
    <!-- </v-list-group> -->
    <!-- </v-list> -->
    <template v-slot:append>
      <e-situacao-caixa v-if="exibirFinanceiro" />
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import EAnoSelector from "@/Pages/Templates/components/EAnoSelector.vue";
import ESituacaoCaixa from "@/Pages/Templates/components/ESituacaoCaixa.vue";

const exibirFinanceiro = process.env.VUE_APP_ATIVAR_FINANCEIRO === "true";
export default {
  name: "MainTemplate",
  components: {
    EAnoSelector,
    ESituacaoCaixa,
  },
  computed: {
    ...mapGetters("Auth", ["user"]),
    // ...mapGetters("Franquias", ["franquias"]),
    ...mapGetters("MainTemplate", ["menuOpen"]),

    menu() {
      return {
        items: [
          {
            icon: "mdi mdi-home",
            title: "Início",
            exibir: true,
            children: [
              {
                title: "Ir para Início",
                to: {
                  name:
                    (this.user ? this.user.roleName : "") === "professor"
                      ? "acessoProfessor.home"
                      : "home",
                },
                icon: "",
              },
              {
                title: "Ir para notifiq",
                to: {
                  name: "notifiq.index",
                },
                permissions: ["notifiq.load"],
                icon: "",
              },
            ],
          },
          {
            icon: "di mdi-book-education",
            title: "Agenda",
            exibir: (this.user ? this.user.roleName : "") === "aluno",
            children: [
              {
                title: "Ir para Agendas",
                to: {
                  name: "agenda.aluno",
                },
                icon: "",
              },
            ],
          },
          {
            icon: "mdi mdi-comment-alert",
            title: "Avisos",
            exibir: true,
            permissions: ["alunos.detalhes"],
            children: [
              // {
              //   title: "Meus avisos",
              //   to: {
              //     name: "avisos.portal",
              //   },
              //   icon: "",
              // },
              {
                title: "Ir para Avisos",
                to: {
                  name:
                    (this.user ? this.user.roleName : "") === "aluno"
                      ? "avisos.portal"
                      : "avisos.index",
                },
                permissions: ["avisos.*", "avisos.listar", "alunos.detalhes"],
                icon: "",
              },
            ],
          },
          {
            icon: "mdi mdi-account-school",
            title: "Matrículas",
            exibir: true,
            children: [
              {
                title: "Ver Matrículas",
                to: { name: "matriculas" },
                permissions: ["matriculas.matricular", "matriculas.*"],
                icon: "",
              },
              // { title: "Adicionar matricula", to: "#", icon: "" },
              {
                title: "Ver Alunos",
                to: { name: "alunos" },
                permissions: ["alunos.criarAluno", "alunos.*"],
                icon: "",
              },
              {
                title: "Pesquisa avançada",
                to: { name: "alunos-pesquisa-avancada" },
                permissions: ["alunos.criarAluno", "alunos.*"],
                icon: "",
              },
            ],
          },
          {
            icon: "mdi mdi-school",
            title: "Turmas",
            exibir: true,
            children: [
              {
                title: "Ver turmas",
                to: { name: "turmas" },
                permissions: ["turmas.criarTurma", "turmas.*"],
                icon: "",
              },
              {
                title: "Gestão de séries",
                to: { name: "series" },
                icon: "fa fa-bookmark",
                permissions: ["series.*", "series.criar"],
              },
              {
                title: "Gestão de disciplinas",
                to: { name: "disciplinas" },
                icon: "fa fa-bookmark",
                permissions: ["disciplinas.*", "disciplinas.criarDisciplina"],
              },
            ],
          },
          {
            icon: "mdi-human-male-board-poll",
            title: "Diário online",
            exibir: true,
            children: [
              {
                title: "Listar todos",
                to: { name: "gestoesDeAulas" },
                icon: "",
                permissions: ["gestoesDeAulas.*", "gestoesDeAulas.criar", "gestoesDeAulas.listar"],
              },
              {
                title: "Gestão geral",
                to: { name: "gestoesDeAulas.geral" },
                icon: "",
                permissions: ["gestoesDeAulas.*", "gestoesDeAulas.gestoesGeral"],
              },
              {
                title: "Agendas",
                to: { name: "agenda.index" },
                icon: "",
                permissions: ["gestoesDeAulas.*", "gestoesDeAulas.listar"],
              },
            ],
          },
          {
            icon: "mdi-note-text",
            title: "Planos",
            exibir: true,
            children: [
              {
                title: "Listar planos",
                to: { name: "gestoesDeAulas.planos" },
                icon: "",
                permissions: ["gestoesDeAulas.planos"],
              },
              {
                title: "Listar planos infantis",
                to: { name: "gestoesDeAulas.planosInfantil" },
                icon: "",
                permissions: ["gestoesDeAulas.planosInfantil"],
              },
            ],
          },

          {
            icon: "fmdi mdi-chart-box",
            title: "Relatórios",
            exibir: true,
            children: [
              {
                title: "Ver todos",
                to: {
                  name: "relatorios",
                  params: { franquiaId: this.user.franquia.id },
                },
                icon: "",
                permissions: ["relatorios.*"],
              },
            ],
          },
          {
            icon: "fmdi mdi-account-switch",
            title: "Usuários",
            exibir: true,
            children: [
              {
                title: "Ver Professores",
                to: { name: "professores" },
                icon: "fa fa-user-tie",
                permissions: ["users.*", "users.gestor"],
              },
              {
                title: "Bloqueios/Liberações",
                to: { name: "usuarios.listaDeUsuariosParaBloquearLiberar" },
                icon: "fa fa-bookmark",
                permissions: ["users.*"],
              },
              {
                title: "Outros",
                to: { name: "usuarios" },
                icon: "fa fa-bookmark",
                permissions: ["users.*"],
              },
            ],
          },
          {
            icon: "mdi mdi-cash-multiple",
            title: "Financeiro",
            exibir: exibirFinanceiro,
            children: [
              {
                title: "Mensalidades",
                to: { name: "mensalidades" },
                permissions: "financeiro.baixarParcela",
                icon: "",
              },
              {
                title: "Gestão de Caixas",
                to: { name: "caixas" },
                permissions: "caixas.abrir",
                icon: "",
              },
            ],
          },
          {
            icon: "mdi mdi-account-network",
            title: "Administração",
            exibir: true,
            children: [
              {
                title: "Dashboard",
                to: { name: "admin" },
                permissions: ["franquias.adm"],
                icon: "",
              },
              {
                title: "Franquias",
                to: { name: "franquias" },
                permissions: ["franquias.adm"],
                icon: "",
              },
              {
                title: "Circuito De Notas",
                to: { name: "circuito-notas" },
                permissions: ["franquias.circuitoAcesso"],
                icon: "",
              },
            ],
          },
          {
            icon: "mdi mdi-help",
            title: "Ajuda e tutorial",
            exibir: true,
            children: [
              // {
              //   title: "Tutoriais",
              //   href: "https://caju.tawk.help/category/e-public",
              //   target: "_blank",
              // },
              // {
              //   title: "Fale conosco",
              //   href: "https://tawk.to/caju",
              //   target: "_blank",
              // },
            ],
          },
        ],
        width: "250px",
      };
    },
  },
  mounted() {
    // this.$Tawk.$setOnLoadFunction(() => this.$Tawk.$showWidget());
  },
  data() {
    return {
      selectedItem: 0,
      openAtualizarSenhaModal: false,
      openSelecionarFranquiaModal: false,
      exibirFinanceiro,
    };
  },
  methods: {
    toggleMenu() {
      this.$store.commit("MainTemplate/setMenuOpen", !this.menuOpen);
    },
  },
};
</script>
