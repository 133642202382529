import Axios from "@/plugins/Axios";
import Agenda from "@/Models/Agenda";

export class AgendaService {
  async syncAll() {
    const response = await Axios().get("notifiq/agendas");
    const data = response.data.map((agenda) => new Agenda(agenda));
    return data;
  }

  async aluno(pageNumber, query) {
    const response = await Axios().get(`notifiq/agendas/aluno?page=${pageNumber}&query=${query}`);
    return response.data;
  }

  async store(form) {
    const response = await Axios().post("notifiq/agendas/store", form);
    return response.data;
  }

  async update(form) {
    const response = await Axios().post(`notifiq/agendas/update`, form);
    return response.data;
  }

  async destroy(agenda_id) {
    const response = await Axios().get(`notifiq/agendas/destroy/${agenda_id}`);
    return response.data;
  }

  async find($agenda_id) {
    const response = await Axios().get(`notifiq/agendas/new-edit/${$agenda_id}`);
    return response.data;
  }

  async turmas() {
    const response = await Axios().get(`notifiq/agendas/turmas`);
    return response.data;
  }

  async notificar(agenda_id) {
    const response = await Axios().get(`notifiq/agendas/notificar/${agenda_id}`);
    return response.data;
  }

  async agendasDaSemana(turma_id) {
    const response = await Axios().get(`notifiq/agendas/agendas-da-semana/${turma_id}`);
    const data = response.data.diasDaSemana.map((agenda) => new Agenda(agenda));

    return data;
  }
}
export default new AgendaService();
