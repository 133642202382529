import AulasIndexPage from "@/Pages/Aulas/index.vue";
import NewEditAulaPage from "@/Pages/Aulas/NewEditAulaPage.vue";
import NewEditAulaPageComRotina from "@/Pages/Aulas/NewEditAulaPageComRotina.vue";
import AulasAguardandoConfirmacao from "@/Pages/Aulas/AulasAguardandoConfirmacao.vue";
import LancamentoDeFrequenciaPage from "@/Pages/Aulas/LancamentoDeFrequenciaPage.vue";

export default [
  {
    path: "/gestoes-de-aulas/:gestaoDeAulaId/aulas/create",
    name: "aulas.create",
    component: NewEditAulaPage,
    props: { editing: false },
  },
  {
    path: "/gestoes-de-aulas/:gestaoDeAulaId/aulas/create/rotina",
    name: "aulas.create.rotina",
    component: NewEditAulaPageComRotina,
    props: { editing: false },
  },
  {
    path: "/gestoes-de-aulas/:gestaoDeAulaId/aulas",
    name: "aulas",
    component: AulasIndexPage,
    props: true,
  },
  {
    path: "/gestoes-de-aulas/aulas-aguardando-confirmacao",
    name: "aulas.aguardandoConfirmacao",
    component: AulasAguardandoConfirmacao,
  },
  {
    path: "/gestoes-de-aulas/:gestaoDeAulaId/aulas/edit/:aula_id",
    name: "aulas.edit",
    component: NewEditAulaPage,
    props: { editing: true },
  },
  {
    path: "/gestoes-de-aulas/:gestaoDeAulaId/aulas/momentos/edit/:aula_id",
    name: "aulas.edit.momentos",
    component: NewEditAulaPageComRotina,
    props: { editing: true },
  },
  {
    path: "/gestoes-de-aulas/:gestaoDeAulaId/aulas/:aula_id/frequencia",
    name: "aulas.frequencia",
    component: LancamentoDeFrequenciaPage,
    props: true,
  },
];
