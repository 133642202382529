import AlunosIndexPage from "@/Pages/Alunos/index.vue";
import NewEditAlunoPage from "@/Pages/Alunos/NewEditAlunoPage.vue";
import AlunosPesquisaAvancada from "@/Pages/Alunos/AlunosPesquisaAvancada.vue";

export default [
  {
    path: "/alunos/",
    name: "alunos",
    component: AlunosIndexPage,
  },
  {
    path: "/alunos/pesquisa-avancada",
    name: "alunos-pesquisa-avancada",
    component: AlunosPesquisaAvancada,
  },
  {
    path: "/alunos/create",
    name: "alunos.create",
    component: NewEditAlunoPage,
    props: { editing: false },
  },
  {
    path: "/alunos/edit/:aluno_id",
    name: "alunos.edit",
    component: NewEditAlunoPage,
    props: { editing: true },
  },
];
