<template>
  <v-navigation-drawer width="400" v-model="localDrawer" right temporary app>
    <v-list-item :class="localAula.color">
      <v-list-item-content>
        <v-list-item-title class="font-weight-medium">{{
          localAula.tipo_de_aula
        }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>Data</v-list-item-title>
        <template v-if="JSON.parse(localAula.momento)">
          <p class="font-weight-light text-justify">
            {{ localAula.data_formatada }} -
            {{ getDescricaoMomento(aula.momento)[0] }}
            {{ getDescricaoMomento(aula.momento)[1] }}
          </p>
        </template>
        <template v-else>
          <p class="font-weight-light text-justify">
            {{ localAula.data_formatada }} -
            {{ localAula.horario ? localAula.horario.descricao : "[NÃO INFORMADO]" }}
          </p>
        </template>
        <v-list-item-title
          v-if="localAula.turma.ano_id >= 4 && localAula.turma.curso_id === 10"
          class="mt-4"
          >Campos de Experiências</v-list-item-title
        >
        <p class="font-weight-light text-justify">
          <template
            v-if="
              localAula.campos_de_experiencias &&
              localAula.campos_de_experiencias.some((campo) => campo !== null)
            "
          >
            <span v-for="(campo, index) in localAula.campos_de_experiencias" :key="index">
              <span v-if="campo">{{ campo }}.<br /></span>
            </span>
          </template>
          <span v-else-if="localAula.turma.ano_id >= 4 && localAula.turma.curso_id === 10"
            >Sem campos de experiência.<br
          /></span>
        </p>
        <v-divider></v-divider>
        <v-list-title v-if="localAula.turma.ano_id >= 4" class="mt-4">Dia da semana</v-list-title>
        <p v-if="localAula.turma.ano_id >= 4" class="font-weight-light text-justify">
          {{ localAula.dia_da_semana }}
        </p>
        <v-divider></v-divider>
        <v-list-item-title
          v-if="localAula.turma.ano_id >= 4 && localAula.turma.curso_id === 10"
          class="mt-4"
          >Rotina</v-list-item-title
        >
        <v-list-item-title v-else class="mt-4">Conteúdo</v-list-item-title>
        <p class="font-weight-light text-justify">
          {{ localAula.conteudo }}
        </p>
        <v-divider></v-divider>
        <v-list-item-title v-if="localAula.turma.curso_id !== 10" class="mt-4"
          >Metodología</v-list-item-title
        >
      
        <p v-if="localAula.turma.curso_id !== 10" class="font-weight-light text-justify">
          {{ localAula.metodologia }}
        </p>
        -->
        <v-divider></v-divider>
        <v-list-item-title class="mt-4">Situação</v-list-item-title>
        <v-list-item-title>
          <v-chip label :color="localAula.color">
            {{ localAula.descricaoSituacao }}
          </v-chip>
        </v-list-item-title>
        <v-list-item-title v-if="localAula.turma.ano_id < 4" class="mt-4">Motivo</v-list-item-title>
        <p v-if="localAula.turma.ano_id < 4" class="font-weight-light">
          {{ localAula.motivo ? localAula.motivo : "Sem motivo." }}
        </p>
      </v-list-item-content>
    </v-list-item>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    aula: {
      type: Object,
      required: true,
    },
  },
  watch: {
    value() {
      this.localDrawer = this.value;
    },
    localDrawer() {
      this.$emit("input", this.localDrawer);
    },
  },
  computed: {
    localAula() {
      return this.aula;
    },
  },
  methods: {
    getDescricaoMomento(itemSelecionado) {
      const momentoSelecionadoDescricao = [];
      const momentoSelecionado = itemSelecionado;

      this.$constants.rotinasTurma.map((item) => {
        return item.children.map((subItem) => {
          if (subItem.id === momentoSelecionado) {
            const pai = subItem.pai;

            const paiName = this.$constants.rotinasTurma.filter((item) => item.id === pai)[0].name;

            momentoSelecionadoDescricao.push(paiName);
            momentoSelecionadoDescricao.push(subItem.name);
          }
        });
      });

      return momentoSelecionadoDescricao;
    },
  },
  data() {
    return {
      localDrawer: this.value,
    };
  },
};
</script>

<style lang="scss" scoped></style>
