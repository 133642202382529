import Axios from "@/plugins/Axios";

class MatriculasService {
  async syncAll() {
    const response = await Axios().get("matriculas");
    return response.data;
  }

  async matricular(form) {
    form.matriculas = form.matriculas.map((matricula) => ({
      aluno_id: matricula.aluno_id,
      turma_id: matricula.turma_id,
      serie_id: matricula.serie_id,
    }));
    const response = await Axios().post("matriculas", form);
    const novaMatricula = response.data;

    return novaMatricula;
    // store.commit("Matriculas/adicionarMatricula", novaMatricula);
  }

  async historico(aluno_id) {
    const response = await Axios().get(`matriculas/historico/${aluno_id}`);
    return response;
  }

  async transferirMatricula(form) {
    const response = await Axios().post("matriculas/transferir-matricula", form);
    return response.data;
  }

  async atualizarMatricula(matricula) {
    const response = await Axios().put(`matriculas/${matricula.id}`, matricula);
    const matriculaAtualizada = response.data;
    // store.commit("Matriculas/atualizarMatricula", matriculaAtualizada);
    return matriculaAtualizada;
  }

  async deletarMatricula(matricula) {
    const response = await Axios().delete(`matriculas/${matricula.id}`);
    const matriculaDeletada = response.data;
    // store.commit("Matriculas/deletarMatricula", matricula.id);
    return matriculaDeletada;
  }

  async recuperarMatricula(matricula) {
    return this.atualizarMatricula({ ...matricula, deleted_at: null });
  }

  async ocultar(matricula) {
    const response = await Axios().get(`matriculas/ocultar/${matricula.id}`);
    const matriculaOcultado = response.data;
    // store.commit("Matriculas/atualizarMatricula", matriculaOcultado);
    return matriculaOcultado;
  }

  async verificarSeAlunoPossuiMatriculaNoMunicipio(aluno_id) {
    const response = await Axios().post(
      "matriculas/verificar-se-aluno-possui-matricula-no-municipio",
      { aluno_id }
    );
    return response.data;
  }

  async getMatricula(matricula_id) {
    const response = await Axios().get(`matriculas/get-matricula/${matricula_id}`);
    return response.data;
    // store.commit("Matriculas/atualizarMatricula", matriculaOcultado);
  }

  async getMediasdaMatricula(matricula_id) {
    const response = await Axios().get(
      `matriculas/desempenho-de-notas-do-aluno/matricula/${matricula_id}`
    );
    return response.data;
  }

  async getFaltasdaMatricula(matricula_id) {
    const response = await Axios().get(
      `matriculas/desempenho-de-faltas-do-aluno/matricula/${matricula_id}`
    );
    return response.data;
  }

  async qtd() {
    const response = await Axios().get("matriculas/qtd");
    return response.data;
  }

  async qtdPorFranquia(franquiaId) {
    const response = await Axios().get(`matriculas/qtdPorFranquia/${franquiaId}`);
    return response.data;
  }

  async taxaEvasao(franquiaId) {
    const response = await Axios().get(`matriculas/taxaEvasao/${franquiaId}`);
    return response.data;
  }

  async bloqueioDeMatriculas(data) {
    const response = await Axios().post(`matriculas/bloqueioDeMatriculas`, data);
    return response.data;
  }

  async verificarEstadoBloqueio() {
    try {
      const response = await Axios().get("matriculas/verificarEstadoBloqueio");
      // console.log(response.data);
      return response.data;
    } catch (error) {
      console.error("Erro ao verificar estado de bloqueio:", error);
      throw error;
    }
  }

  async salvarMovimentacaoMatriculaInterna(matricula) {
    try {
      const response = await Axios().patch(
        `/matriculas/salvarMovimentacaoMatriculaInterna/${matricula.matricula_id}`,
        {
          numero_escola: matricula.dados.numero_escola,
          busca_ativa: matricula.dados.busca_ativa,
          situacao_estudante: matricula.dados.situacao_estudante,
          estudante_escola: matricula.dados.estudante_escola,
          transferencia_ano: matricula.dados.transferencia_ano,
          transferencias: matricula.dados.transferencias,
          observacoes: matricula.dados.observacoes,
          situacao: matricula.dados.situacao,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(`Erro ao atualizar matrícula: ${error.message}`);
    }
  }

  async salvarMovimentacaoAlunoExterno(movimentacao) {
    try {
      const response = await Axios().patch(`/matriculas/salvarMovimentacaoAlunoExterno`, {
        id: movimentacao.id,
        aluno_id: movimentacao.aluno_id,
        matricula_id: movimentacao.matricula_id,
        ano: movimentacao.ano,
        serie: movimentacao.serie,
        numero_escola: movimentacao.numero_escola,
        instituicao_ensino: movimentacao.instituicao_ensino,
        busca_ativa: movimentacao.busca_ativa,
        situacao_estudante: movimentacao.situacao_estudante,
        estudante_escola: movimentacao.estudante_escola,
        transferencia_ano: movimentacao.transferencia_ano,
        transferencias: movimentacao.transferencias,
        observacoes: movimentacao.observacoes,
        situacao_final: movimentacao.situacao_final,
        inep: movimentacao.inep,
        municipio: movimentacao.municipio,
        estado: movimentacao.estado,
        estudante_novo_repetente: movimentacao.estudante_novo_repetente,
      });

      return response.data;
    } catch (error) {
      throw new Error(`Erro ao atualizar a movimentação: ${error.message}`);
    }
  }

  async movimentacoesMatriculaInterna(matriculaId) {
    try {
      const response = await Axios().get(
        `/matriculas/movimentacoesMatriculaInterna/${matriculaId}`
      );
      return response.data;
    } catch (error) {
      throw new Error("Não foi possível buscar as movimentações.");
    }
  }

  async movimentacoesAlunoExterno(alunoId, matriculaId) {
    try {
      const response = await Axios().get(
        `/matriculas/movimentacoesAlunoExterno/${alunoId}/${matriculaId}`
      );
      return response.data;
    } catch (error) {
      throw new Error("Não foi possível buscar as movimentações.");
    }
  }

  // MOVIMENTAÇÃO HISTORICO
  async salvarHistoricoInterno(matricula) {
    try {
      const response = await Axios().patch(
        `/matriculas/salvarHistoricoInterno/${matricula.matricula_id}`,
        {
          educacao_infantil: matricula.dados.educacao_infantil,
          observacoes: matricula.dados.observacoes,
          situacao_estudante: matricula.dados.situacao_estudante,
          notas: matricula.dados.notas,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(`Erro ao atualizar matrícula: ${error.message}`);
    }
  }

  async salvarMovimentacaoHistoricoExterno(movimentacao) {
    try {
      const response = await Axios().patch(`/matriculas/salvarMovimentacaoHistoricoExterno`, {
        id: movimentacao.id,
        aluno_id: movimentacao.aluno_id,
        matricula_id: movimentacao.matricula_id,
        ano: movimentacao.ano,
        educacao_infantil: movimentacao.educacao_infantil,
        inep: movimentacao.inep,
        serie: movimentacao.serie,
        instituicao_ensino: movimentacao.instituicao_ensino,
        municipio: movimentacao.municipio,
        estado: movimentacao.estado,
        observacoes: movimentacao.observacoes,
        situacao_final: movimentacao.situacao_final,
        notas: movimentacao.notas,
      });

      return response.data;
    } catch (error) {
      throw new Error(`Erro ao atualizar a movimentação: ${error.message}`);
    }
  }

  async movimentacoesHistoricoInterno(matriculaId) {
    try {
      const response = await Axios().get(
        `/matriculas/movimentacoesHistoricoInterno/${matriculaId}`
      );
      return response.data;
    } catch (error) {
      throw new Error("Não foi possível buscar as movimentações.");
    }
  }

  async movimentacoesHistoricoExterno(alunoId, matriculaId) {
    try {
      const response = await Axios().get(
        `/matriculas/movimentacoesHistoricoExterno/${alunoId}/${matriculaId}`
      );
      return response.data;
    } catch (error) {
      throw new Error("Não foi possível buscar as movimentações.");
    }
  }

  async existeHistoricoInterno(matriculaId) {
    try {
      const response = await Axios().get(
        `/matriculas/verificar-historico-interno/${matriculaId}`
      );
      return response.data;
    } catch (error) {
      throw new Error("Não foi possível buscar as movimentações.");
    }
  }
}

export default new MatriculasService();
